import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export type Review = {
  poor: number;
  average: number;
  good: number;
}

export type ReviewScore = {
  percentages: Review;
  counts: Review;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  loading: boolean;
  totalChats: number;
  totalActiveChats: number;
  totalPartners: number;
  totalDigitalAssistants: number;
  totalChatsInCurrentWeek: number;
  chatActivity: Record<string, number>;
  chatActivityThisWeek: Record<string, number>;
  countOfChatsByMode: Record<string,number>;
  reviewScores: ReviewScore;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Dashboard4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  chatStatisticsCallId: string = "";
  aggregateDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      loading: false,
      totalChats: 0,
      totalActiveChats: 0,
      totalPartners: 0,
      totalDigitalAssistants: 0,
      totalChatsInCurrentWeek: 0,
      chatActivity: {
        Jan: 0,
        Fe: 0,
        Mar: 0,
        Apr: 0,
        Ma: 0,
        Jun: 0,
        Jul: 0,
        Au: 0,
        Sep: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
      },
      chatActivityThisWeek: {
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
        Sun: 0,
      },
      countOfChatsByMode: {
        web: 0,
        mobile: 0,
      },
      reviewScores: {
        percentages: { poor: 0, average: 0, good: 0 },
        counts: { poor: 0, average: 0, good: 0 },
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      await this.handleRestAPIResponse(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token, loading: true})
    this.handleFetchChatStatistics(token)
    this.handleFetchAggregateData(token)
  }

  handleRestAPIResponse = async (message: Message) => {
    const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}

    if(this.chatStatisticsCallId === responseId) {
      responseHandlers[this.chatStatisticsCallId] = this.handleChatStatisticsResponse
    }

    if(this.aggregateDataCallId === responseId) {
      responseHandlers[this.aggregateDataCallId] = this.handleAggregateDataResponse
    }

    const handler = responseHandlers[responseId]
    if(handler) {
      handler(message)
    }
  }

  handleChatStatisticsResponse = async(message: Message) => {
    const statisticsResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({loading: false})
    if(!statisticsResponse) {
      toast.error("Failed to fetch", { className: "error__toast" })
      return;
    }

    if(statisticsResponse.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(statisticsResponse.total_chats_per_month) {
      const { total_chats, total_chats_per_month, digital_assistants, total_this_week_chats, total_active_chats, review_scores, total_weeks_chats } = statisticsResponse
      const newChatActivity: Record<string, number>= {
        Jan: total_chats_per_month.January,
        Fe: total_chats_per_month.February,
        Mar: total_chats_per_month.March,
        Apr: total_chats_per_month.April,
        Ma: total_chats_per_month.May,
        Jun: total_chats_per_month.June,
        Jul: total_chats_per_month.July,
        Au: total_chats_per_month.August,
        Sep: total_chats_per_month.September,
        Oct: total_chats_per_month.October,
        Nov: total_chats_per_month.November,
        Dec: total_chats_per_month.December
      }
      const newChatActivityInCurrentWeek = {
        Mon: total_weeks_chats.Monday,
        Tue: total_weeks_chats.Tuesday,
        Wed: total_weeks_chats.Wednesday,
        Thu: total_weeks_chats.Thursday,
        Fri: total_weeks_chats.Friday,
        Sat: total_weeks_chats.Saturday,
        Sun: total_weeks_chats.Sunday
      }
      this.setState({
        chatActivity: newChatActivity,
        totalDigitalAssistants: digital_assistants,
        totalChatsInCurrentWeek: total_this_week_chats,
        totalActiveChats: total_active_chats,
        reviewScores: review_scores,
        chatActivityThisWeek: newChatActivityInCurrentWeek,
        totalChats: total_chats
      })
    }
  }

  handleAggregateDataResponse = async(message: Message) => {
    const aggregateData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({loading: false})
    if(!aggregateData) {
      toast.error("Failed to fetch", { className: "error__toast" })
      return;
    }

    if(aggregateData.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(aggregateData.mobile_percentage) {
      this.setState({
        countOfChatsByMode: {
          web: aggregateData.desktop_percentage,
          mobile: aggregateData.mobile_percentage,
        },
      })
    }
  }

  handleFetchChatStatistics = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token
    }

    const newRequest = new Message(getName(MessageEnum.RestAPIRequestMessage))
    newRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    newRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardEndPoint
    )

    newRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )

    this.chatStatisticsCallId = newRequest.messageId

    this.send(newRequest)
  }

  handleFetchAggregateData = (token: string) => {
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token 
      })
    )

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aggregateDataEndPoint
    )

    this.aggregateDataCallId = request.messageId

    this.send(request)
  }

  handleLogout = async() => {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()

    const navigationMessage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(navigationMessage)
  }

  // Customizable Area End
}
