import { Typography, styled } from "@material-ui/core";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { TooltipItem } from "chart.js";

type DougnutProps = {
    countOfChatsByMode: Record<string,number>;
}

const chartSetting = {
    borderradius: "8px"
};

export const drawPlugin = {
    id: 'drawPlugin',
    afterDraw: (chart: { ctx: any; data: { datasets: any; }; getDatasetMeta: (arg0: any) => any; }) => {
        const ctx = chart.ctx;
        const datasets = chart.data.datasets;

        datasets.forEach((_dataset: any, i: any) => {
            const meta = chart.getDatasetMeta(i);
            meta.data.forEach((element: { tooltipPosition: () => { x: any; y: any; }; _index: any; $context: any }, _index: any) => {
                const dataset = datasets[i];
                const dataValue = dataset.data[element.$context.dataIndex];

                if (dataValue === 0) {
                    return;
                }

                const { x, y } = element.tooltipPosition();
                const radius = 6;
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
                ctx.fillStyle = 'transparent';
                ctx.fill();
                ctx.lineWidth = 2;
                ctx.strokeStyle = '#FFFFFF';
                ctx.stroke();
            });
        });
    },
};
export const callbacks = function (data: { labels: any; datasets: any; }) {
    return {
        labelTextColor: function () {
            return '#0F172A';
        },
        label: function (tooltipItem: TooltipItem<"doughnut">) {
            let label = data.labels[tooltipItem.dataIndex] || '';
            if (label) {
                label += ': ';
            }
            label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex] + '%';
            return label;
        }
    }
}

export default function Dougnut({ countOfChatsByMode }: DougnutProps) {

    const ChatsMode = [{ id: 1, mode: "Mobile", percentage: countOfChatsByMode.mobile }, { id: 2, mode: "Web", percentage: countOfChatsByMode.web }]


    const data = {
        labels: ChatsMode.map(chat => chat.mode),
        datasets: [{
            label: 'My First Dataset',
            data: ChatsMode.map(chat => chat.percentage),
            backgroundColor: [
                "#4DA2AA",
                "#314A84"
            ],
            hoverOffset: 4
        }]
    };

    return <DougnutChartBox>
        <Typography component={"h1"}>Counts of Chats by Mode</Typography>
        <div className="dougnut-box">
            <div className="dougnut">
                <Doughnut data={data} {...chartSetting} plugins={[drawPlugin]} options={{
                    cutout: "60%", plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            backgroundColor: '#F1F5F9',
                            bodyColor: '#F1F5F9',
                            borderColor: '#F1F5F9',
                            borderWidth: 1,
                            titleFont: { weight: 'bold' },
                            callbacks: callbacks(data)
                        },
                        datalabels: {
                            display: false,
                        },
                    }
                }} />
            </div>
            <div>
                <h2 style={{ color: "#0F172A", letterSpacing: "-0.15px", lineHeight: "34px", fontSize: "16px", fontWeight: 700 }}>Mode</h2>
                {ChatsMode.map(data => (<div key={data.id} className="chat-data">
                    <div style={{ backgroundColor: data.mode === "Mobile" ? "#51ABB3" : "#314A84" }} />
                    <p>{`${data.mode} - `}</p>
                    <span>{`${data.percentage}%`}</span>
                </div>))}
            </div>
        </div>
    </DougnutChartBox>
}

const DougnutChartBox = styled("div")({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "200px",
    borderRadius: 8,
    gap: 24,
    padding: "24px",

    "& .dougnut-box": {
        display: "flex",
        flexDirection: "row",
        gap: "40px"
    },
    "& .dougnut-box .dougnut": {
        width: "149px",
        height: "149px"
    },
    "& .chat-mode": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
    "& h1": {
        color: "#64748B",
        fontSize: "16px",
        margin: 0,
        fontWeight: 700,
        fontFamily: "Cairo",
        lineHeight: "20px"
    },
    "& h2": {
        color: "#0F172A",
        fontSize: "16px",
        margin: 0,
        fontFamily: "Cairo",
    },
    "& .chat-data": {
        display: "flex",
        alignItems: "center",
        gap: 4,
        fontSize: "12px"
    },
    "& .chat-data div": {
        width: "12px",
        height: "12px",
        borderRadius: 50,
    },
    "& .chat-data p": {
        margin: 0,
        color: "#0F172A",
        size: "12px",
        fontFamily: "Cairo",
    },
    "& .chat-data span": {
        fontWeight: 700,
        fontFamily: "Cairo",
    },
});
