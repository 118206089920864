import React from "react";
import { Line } from "react-chartjs-2";
import { Typography, styled } from "@material-ui/core";

const chartSetting = {
    width: 500,
    borderradius: "8px"
};

type LineChartProps = {
    totalChats: number;
    currentWeekChats: Record<string, number>;
}

export default function LineChart({ totalChats, currentWeekChats }: LineChartProps) {

    const currentWeekChatsData = Object.keys(currentWeekChats).map((key) => ({day: key, chats: currentWeekChats[key]}))

    const data = {
        labels: currentWeekChatsData.map(label => label.day),
        datasets: [{
            data: currentWeekChatsData.map(label => label.chats),
            fill: false,
            borderColor: '#51ABB3',
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 2,
            fontFamily: "Cairo",
        }]
    };

    return (
        <LinearChartBox>
            <Typography component={"h2"} >Total Chats this week</Typography>
            <Typography component={"h1"} >{totalChats}</Typography>
            <Line 
                data={data} 
                options={{
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            backgroundColor: '#F1F5F9',
                            bodyColor: '#F1F5F9',
                            borderColor: '#F1F5F9',
                            borderWidth: 1,
                            titleColor: "#0F172A",
                            titleFont: { weight: 'bold' },
                            callbacks: {
                                labelTextColor: function (context) {
                                    return '#0F172A';
                                }
                            }
                        },
                        datalabels: {
                            display: false,
                        },
                    }, scales: {
                        x: {
                            display: false
                        },
                        y: {
                            display: false
                        }
                    }, responsive: true
                }} 
                {...chartSetting} 
            />
        </LinearChartBox>
    )
}

const LinearChartBox = styled("div")({
    display: "flex",
    flexDirection: "column",

    "& h2": {
        color: "#6F96AA",
        fontSize: "14px",
        fontFamily: "Cairo",
        fontWeight: 700,
        lineHeight: "26.24px"
    },

    "& h1": {
        color: "#14172E",
        fontSize: "24px !important",
        fontWeight: 700,
        fontFamily: "Cairo",
        lineHeight: "44.98px"
    }
});