import { Typography, styled } from "@material-ui/core";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import ProgressBar from "./ProgressBar";
import { callbacks, drawPlugin } from "./Dougnut";
import { ReviewScore } from "../Dashboard4Controller";

type HalfDougnutProps = {
    reviewScores: ReviewScore;
}

const chartSetting = {
    borderradius: "8px"
};

export default function HalfDougnut({
    reviewScores: { percentages, counts }
}: HalfDougnutProps) {

    const maxValue = counts.average + counts.poor + counts.good

    const data = {
        labels: ["Poor", "Average", "Good"],
        datasets: [{
            label: 'Review score',
            data: [percentages.poor, percentages.average, percentages.good],
            backgroundColor: [
                '#0F62FE',
                '#F59E0B',
                '#059669'
            ],
            hoverOffset: 4,
        }]
    };
    return (
        <DougnutChartBox>
            <Typography component={"h1"}>Average Review Score</Typography>
            <div style={{ height: "100%", display: "flex", flexDirection: "row", gap: "24px", alignItems: "center" }}>
                <div style={{ width: "265px", height: "131.5px", alignItems: "center", position: "relative", display: "flex" }}>
                    <Doughnut data={data} plugins={[drawPlugin]} options={{
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                enabled: true,
                                backgroundColor: '#F1F5F9',
                                bodyColor: '#F1F5F9',
                                borderColor: '#F1F5F9',
                                borderWidth: 1,
                                titleFont: { weight: 'bold' },
                                callbacks: callbacks(data)
                            },
                            datalabels: {
                                display: false,
                            },
                        },
                        rotation: -90,
                        offset: 4,
                        circumference: 180,
                        cutout: "82%",
                        maintainAspectRatio: true,
                        responsive: true
                    }} {...chartSetting} />
                    <div
                        style={{
                            position: "absolute",
                            top: "142px",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center"
                        }}
                    >
                        <Typography component={"h1"} style={{ fontSize: "32px", color: "#334155", fontWeight: 700, lineHeight: "22px" }}>Average</Typography>
                    </div>
                </div>
                <div style={{ width: "366px", maxHeight: "120px", height: "100%" }}>
                    <ProgressBar value={counts.poor} title="poor" maxValue={maxValue}  />
                    <ProgressBar value={counts.average} title="average" maxValue={maxValue}  />
                    <ProgressBar value={counts.good} title="good" maxValue={maxValue}  />
                </div>
            </div>
        </DougnutChartBox>
    )
}

const DougnutChartBox = styled("section")({
    display: "flex",
    flexDirection: "column",
    maxHeight: "200px",
    marginBottom: "24px",
    borderRadius: 8,
    padding: "24px",
    gap: "24px",
    "& h2": {
        color: "#6F96AA",
        fontSize: "14px",
        fontFamily: "Cairo",
    },
    "& h1": {
        color: "#64748B",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Cairo",
        lineHeight: "22px"
    }
});
