import React from "react";
import { Typography, styled } from "@material-ui/core";


type CardProps = {
    totalActiveChats: number;
    totalChats: number;
    totalPartners: number;
    totalDigitalAssistants: number;
}

const Container = styled("div")({
    display: "flex",
    gap: 24,
    justifyContent: "space-between",
    flexWrap: "wrap",
})

const Card = styled("div")({
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 24,
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    gap: 10,
    '& .icon': {
        boxSizing: "border-box",
        margin: "0 4px 0 24px",
    },
    '& .label': {
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "20px",
        color: " #64748B",
    },
    '& .value': {
        marginTop: 4,
        fontFamily: "Cairo",
        fontWeight: 700,
        fontSize: "32px",
        lineHeight: "34px",
        color: "#0F172A",
        letterSpacing: "-0.15px"
    }
})

export default function Cards({
    totalActiveChats,
    totalDigitalAssistants,
    totalChats,
}: CardProps) {
    const options = [
        {
            title: "Chats",
            value: totalChats,
            icon: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M31.6667 5H8.33333C6.5 5 5 6.5 5 8.33333V20C5 21.8333 6.5 23.3333 8.33333 23.3333H31.6667C33.5 23.3333 35 21.8333 35 20V8.33333C35 6.5 33.5 5 31.6667 5ZM31.6667 15H26.4333C25.65 15 25.0333 15.55 24.8167 16.3C24.2167 18.4 22.25 20 20 20C17.75 20 15.7833 18.4 15.1833 16.3C14.9667 15.55 14.35 15 13.5667 15H8.33333V10C8.33333 9.08333 9.08333 8.33333 10 8.33333H30C30.9167 8.33333 31.6667 9.08333 31.6667 10V15ZM33.3333 26.6667H26.45C25.6667 26.6667 25.0333 27.2333 24.8167 28C24.2333 30.1167 22.3 31.6667 20 31.6667C17.7 31.6667 15.7667 30.1167 15.1833 28C14.9667 27.2333 14.3333 26.6667 13.55 26.6667H6.66667C5.75 26.6667 5 27.4167 5 28.3333V31.6667C5 33.5 6.5 35 8.33333 35H31.6667C33.5 35 35 33.5 35 31.6667V28.3333C35 27.4167 34.25 26.6667 33.3333 26.6667Z" fill="#7D6AEB" />
            </svg>
        },
        {
            title: "Active Chats",
            value: totalActiveChats,
            icon: <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.1496 7.24162C38.3996 6.67495 30.9329 0.574951 19.7496 0.574951C8.54961 0.574951 1.09961 6.67495 0.349609 7.24162L17.1496 28.175C18.4829 29.8416 21.0163 29.8416 22.3496 28.175L39.1496 7.24162Z" fill="#2ED67B" />
            </svg>
    
        },
        {
            title: "Partners",
            value: 56,
            icon: <svg width="41" height="22" viewBox="0 0 41 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M30.5 10.1667C33.2667 10.1667 35.4833 7.93341 35.4833 5.16675C35.4833 2.40008 33.2667 0.166748 30.5 0.166748C29.9667 0.166748 29.45 0.250081 28.9833 0.400081C29.9333 1.75008 30.4833 3.38341 30.4833 5.16675C30.4833 6.95008 29.9167 8.56675 28.9833 9.93341C29.45 10.0834 29.9667 10.1667 30.5 10.1667ZM12.1667 8.50008H8.83333V5.16675C8.83333 4.25008 8.08333 3.50008 7.16667 3.50008C6.25 3.50008 5.5 4.25008 5.5 5.16675V8.50008H2.16667C1.25 8.50008 0.5 9.25008 0.5 10.1667C0.5 11.0834 1.25 11.8334 2.16667 11.8334H5.5V15.1667C5.5 16.0834 6.25 16.8334 7.16667 16.8334C8.08333 16.8334 8.83333 16.0834 8.83333 15.1667V11.8334H12.1667C13.0833 11.8334 13.8333 11.0834 13.8333 10.1667C13.8333 9.25008 13.0833 8.50008 12.1667 8.50008ZM27.15 5.16675C27.15 7.93341 24.9333 10.1667 22.1667 10.1667C19.4 10.1667 17.1667 7.93341 17.1667 5.16675C17.1667 2.40008 19.4 0.166748 22.1667 0.166748C24.9333 0.166748 27.15 2.40008 27.15 5.16675ZM22.1667 13.5001C18.8333 13.5001 12.1667 15.1667 12.1667 18.5001V20.1667C12.1667 21.0834 12.9167 21.8334 13.8333 21.8334H30.5C31.4167 21.8334 32.1667 21.0834 32.1667 20.1667V18.5001C32.1667 15.1667 25.5 13.5001 22.1667 13.5001ZM35.5 18.5001C35.5 16.5334 34.5833 14.9834 33.2 13.7667C36.55 14.3501 40.5 15.9334 40.5 18.5001V21.0001C40.5 21.4667 40.1333 21.8334 39.6667 21.8334H35.4167C35.4667 21.5667 35.5 21.2834 35.5 21.0001V18.5001Z" fill="#3CC2E0" />
            </svg>
    
        },
        {
            title: "Digital Assistants",
            value: totalDigitalAssistants,
            icon: <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5837 14.6667C17.5837 10.2485 21.1654 6.66675 25.5837 6.66675C30.0019 6.66675 33.5837 10.2485 33.5837 14.6667C33.5837 19.085 30.0019 22.6667 25.5837 22.6667C21.1654 22.6667 17.5837 19.085 17.5837 14.6667ZM12.2503 18.6667C10.3449 18.6667 8.58423 19.6833 7.63152 21.3334C6.67882 22.9836 6.67882 25.0166 7.63152 26.6667C8.58423 28.3169 10.3449 29.3334 12.2503 29.3334C14.1557 29.3334 15.9164 28.3169 16.8691 26.6667C17.8218 25.0166 17.8218 22.9836 16.8691 21.3334C15.9164 19.6833 14.1557 18.6667 12.2503 18.6667ZM24.917 26.6667C23.076 26.6667 21.5837 28.1591 21.5837 30.0001C21.5837 31.841 23.076 33.3334 24.917 33.3334C26.7579 33.3334 28.2503 31.841 28.2503 30.0001C28.2503 28.1591 26.7579 26.6667 24.917 26.6667Z" fill="#FF606B" />
            </svg>
    
        },
    ]
    return (
        <Container>
            {
                options.map((option) => (
                    <Card id={option.title} key={option.title} >
                        <div className="icon">
                            {option.icon}
                        </div>

                        <div>
                            <Typography className="label" >{option.title}</Typography>
                            <Typography component={"h1"} className="value" >{option.value.toLocaleString()}</Typography>
                        </div>
                    </Card>
                ))
            }
        </Container>
    )
}
