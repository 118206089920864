import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  styled,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import Sidebar from "../../../components/src/Sidebar.web";

import { theme as styledTheme } from "./theme.web";
const { Column, StyledRow } = styledTheme
import Cards from "./components/Cards";
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import Dougnut from "./components/Dougnut";
import HalfDougnut from "./components/HalfDougnut";

import { Chart as ChartJS, registerables } from "chart.js";
registerables?.forEach(registerable => ChartJS.register(registerable));
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ChartDataLabels)

const OverlaySpinnerContainer = styled("div")({
  position: "fixed",
  inset: 0,
  zIndex: 100,
  width: "100vw",
  height: "100dvh",
  display: "grid",
  placeItems: "center",
  backgroundColor: "#0F172A70",
  backdropFilter: "blur(4px)",
})

// Customizable Area End

import Dashboard4Controller, {
  Props,
  configJSON,
} from "./Dashboard4Controller";

export default class DashboardWeb4 extends Dashboard4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardContent>
        <Sidebar navigation={this.props.navigation} activeMenu="dashboard" />
        { this.state.loading && (
          <OverlaySpinnerContainer>
            <CircularProgress style={{height: 60, width: 60, color: "#FFFFFF"}} />
          </OverlaySpinnerContainer>
        )}
        <div style={{width: "100%"}}>
          <CustomAppHeader title={"Home"} navigation={this.props.navigation} showSearchBox={true} />
          <div className="rightside-content">
            <Cards 
              data-testid="cards"
              totalActiveChats={this.state.totalActiveChats}
              totalChats={this.state.totalChats}
              totalDigitalAssistants={this.state.totalDigitalAssistants}
              totalPartners={this.state.totalPartners}
            />
            <div className="chat-activity-box">
              <Typography style={{ color: "#64748B" }} component={"h1"}>Chat Activity</Typography>
              <Grid container spacing={3} className="dashboard-chart-box">
                <Grid item xs={9}>
                  <BarChart chatActivity={this.state.chatActivity} />
                </Grid>
                <Grid item xs={3}>
                  <LineChart data-testid="line-chart" totalChats={this.state.totalChatsInCurrentWeek} currentWeekChats={this.state.chatActivityThisWeek} />
                </Grid>
              </Grid>
            </div>
            <Column className="mode-box">
              <div className="dashboard-chart-box" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "24px", flexWrap: "wrap" }}>
                <div className="dougnut-box-1" style={{ minWidth: "496px", height: "241px", boxShadow: "0px 2px 8px 0px #00000014", borderRadius: "8px", flexGrow: 1, backgroundColor: "#FFFFFF" }} >
                  <Dougnut data-testid="mode-view" countOfChatsByMode={this.state.countOfChatsByMode}  />
                </div>
                <div className="dougnut-box-2" style={{ minWidth: "704px", height: "241px", boxShadow: "0px 2px 8px 0px #00000014", overflow: "hidden", borderRadius: "8px", flexGrow: 1, backgroundColor: "#FFFFFF" }}>
                  <HalfDougnut reviewScores={this.state.reviewScores} />
                </div>
              </div>
            </Column>
          </div>
        </div>
      </DashboardContent>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DashboardContent = styled("div")({
  display: "flex",
  backgroundColor: "#FAFAFA",

  "& .rightside-content": {
    display: "flex",
    gap: 24,
    margin: "20px 48px",
    flexDirection: "column",
  },

  "& .dashboard-chart-box": {
    height: "100%",
  },
  "& .dougnut-box-1": {
    "@media(max-width:1400px)": {
      width: "40% !important"
    },
    "@media(max-width:1300px)": {
      width: "100% !important",
    }
  },
  "& .dougnut-box-2": {
    "@media(max-width:1400px)": {
      width: "60% !important",
    },
    "@media(max-width:1300px)": {
      width: "100% !important",
    }
  },

  "& .chat-activity-box": {
    padding: "24px",
    height: "100%",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: 8,
    backgroundColor: "#FFFFFF"
  },

  "& .chat-activity-box h1": {
    margin: "0 0 28px 0",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Cairo",
  },
});
// Customizable Area End
