Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "dashboard4";
exports.labelBodyText = "dashboard4 Body";

exports.btnExampleTitle = "CLICK ME";
exports.dashboardEndPoint = "bx_block_chatbot6/chat_statistics";
exports.aggregateDataEndPoint = "bx_block_visual_analytics/audiences/aggregate_data";
// Customizable Area End