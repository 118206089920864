import React from "react";
import { Bar } from "react-chartjs-2"

const chartSetting = {
    width: 500,
    borderradius: "8px"
};

type BarChartProps = {
    chatActivity: Record<string, number>
}

export default function BarChart({ chatActivity }: BarChartProps) {

    const chatActivityData = Object.keys(chatActivity).map((key) => ({month: key, chats: chatActivity[key]}))

    const chartData = {
        labels: chatActivityData.map((activity) => activity.month),
        datasets: [
            {
                label: "Chats",
                data: chatActivityData.map((activity) => activity.chats),
                backgroundColor: "#314A84",
                borderRadius: 4
            },
        ]
    }

    return (
        <Bar
            data={chartData}
            options={{
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        backgroundColor: '#F1F5F9',
                        bodyColor: '#F1F5F9',
                        titleColor: "#0F172A",
                        borderColor: '#F1F5F9',
                        borderWidth: 1,
                        titleFont: { weight: 'bold' },
                        callbacks: {
                            labelTextColor: function (context) {
                                return '#0F172A';
                            }
                        }
                    },
                    datalabels: {
                        display: false,
                    },
                }, scales: {
                    x: {
                        grid: { display: false }
                    },
                    y: {
                        grid: { borderDash: [5, 5], borderColor: "transparent", tickColor: "transparent" },
                    }
                }, responsive: true
            }}
            {...chartSetting}
        />
    )
}